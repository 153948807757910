<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" title="Navigation"></v-app-bar-nav-icon>
        <v-img src="/img/TW-Mowing_Logo.png" height="40px" width="40px" style="border: white 1px solid;border-radius: 20px;"></v-img>
        <h1>TW <span class="d-none d-sm-inline">Lawnmowing Services</span><span class="d-sm-none">Mowing</span></h1>
      </div>

      <v-spacer></v-spacer>

      <v-btn rel="noopener" x-large href="https://www.facebook.com/kenwick70" target="_blank" icon title="Visit my Facebook page"
        ><v-icon>mdi-facebook</v-icon></v-btn>
      <v-btn rel="noopener" x-large href="http://m.me/kenwick70" target="_blank" icon title="Contact me on Facebook Messenger"
        ><v-icon>mdi-facebook-messenger</v-icon></v-btn>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" fixed temporary>
      <v-list nav dense>
        <v-list-item-group v-model="group" active-class="text--accent-4">
          <a @click="scrollMeTo('home')">
            <v-list-item>
              <v-list-item-title> Welcome </v-list-item-title>
            </v-list-item>
          </a>
          <a @click="scrollMeTo('services')">
            <v-list-item>
              <v-list-item-title> Services </v-list-item-title>
            </v-list-item>
          </a>
          <a @click="scrollMeTo('toddsturf')">
            <v-list-item>
              <v-list-item-title> Todds Turf </v-list-item-title>
            </v-list-item>
          </a>
          <a @click="scrollMeTo('contact')">
            <v-list-item>
              <v-list-item-title> Contact </v-list-item-title>
            </v-list-item>
          </a>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <Home />
      <Services />
      <ToddsTurf />
      <Contact />
      <Phone />
    </v-main>
    <v-footer>
      &copy; 2021 Copyright &nbsp;
      <a rel="noopener" style="margin-left: 10px" href="https://technostropic.com" target="_blank"
        >technostropic</a
      ></v-footer
    >
  </v-app>
</template>

<script>
import Home from "./views/Home.vue";
import Services from "./views/Services";
import ToddsTurf from "./views/ToddsTurf";
import Contact from "./views/Contact";
import Phone from "./components/Phone";

export default {
  name: "App",
  components: {
    Home,
    Services,
    ToddsTurf,
    Contact,
    Phone,
  },
  data: () => ({
    drawer: false,
    group: null,
  }),
  methods: {
    scrollMeTo(refName) {
      var x = document.getElementsByClassName(refName);
      var top = x[0].offsetTop;

      window.scrollTo(0, top);
    },
  },
};
</script>
<style lang="scss">
$text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.8);
$margin: 10px;
.phone {
  font-size:2rem!important;
  font-family: fantasy;
  i {
    margin-left: $margin;
  }
}
h1 {
  margin-left: $margin;
  font-size: 1.6em;
  white-space: nowrap;
  span {
    margin-right: $margin;
  }
}
.v-card > .v-image {
  height: 15vw;
  min-height: 100px;
  h2 {
    text-shadow: $text-shadow;
  }
}
.v-card__text {
  font-size: 1.1rem;
  .img-embed {
    width: 20vw;
    min-width: 200px;
    float: left;
    margin-right: $margin;
  }
}
.v-responsive__content {
  display: flex;
}
.contact {
  li {
    list-style: none;
  }
  a {
    text-decoration: none;
  }
}
</style>
