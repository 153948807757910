<template>
  <v-container ref="services" class="services">
    <v-row ref="services">
      <v-col>
        <v-card>
          <v-img src="/img/services.png" position="top" class="d-flex" alt="Services" title="Services">
            <v-card-title class="white--text d-flex">
              <h2 class="align-self-end">Services</h2>
            </v-card-title>
          </v-img>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="4" lg="3" order="1" order-sm="2"><image-compare title="Bush before and after" :before="before" :after="after"/></v-col>
              <v-col sm="8" lg="9" order="2" order-sm="1"
                ><h3>Lawnmowing</h3>
                <p>
                  Keep your lawn in top condition with a regular Mowing
                </p>
                <h3>Edging</h3>
                <p>
                  Make sure your lawn has the best presentation with crisp,
                  sharp edges
                </p>
                <h3>Whipper snipping</h3>
                <p>
                  Got an area thats out of control, let Todd take care of it
                </p>
                <h3>Hedging</h3>
                <p>
                  Make your property stand out with well trimmed hedging
                </p>
                <h3>Weed control and fertilizing</h3>
                <p>
                  Regular treatment keeps weeds in check and plants looking
                  their best
                </p></v-col
              >
              <v-col cols="12" sm="12" lg="12" order="3" order-sm="3"
                ><image-compare title="Hedge and lawn before and after" :before="beforehedge" :after="afterhedge"
              /></v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "Services",
  components: {},
  data() {
    return {
      before: "/img/bush-after.png",
      after: "/img/bush-before.png",
      beforehedge: "/img/hedge-wide-after.png",
      afterhedge: "/img/hedge-wide-before.png",
    };
  },
};
</script>
